import { cva } from 'cva';
import React from 'react';

import { ESpacerSize } from './constants';

const sizes = {
  [ESpacerSize.s0]: 'h-0',
  [ESpacerSize.s4]: 'h-1',
  [ESpacerSize.s6]: 'h-1.5',
  [ESpacerSize.s8]: 'h-2',
  [ESpacerSize.s12]: 'h-3',
  [ESpacerSize.s16]: 'h-4',
  [ESpacerSize.s20]: 'h-4',
  [ESpacerSize.s24]: 'h-4',
  [ESpacerSize.s32]: 'h-8',
  [ESpacerSize.s40]: 'h-10',
  [ESpacerSize.s48]: 'h-12',
  [ESpacerSize.s56]: 'h-14',
  [ESpacerSize.s72]: 'h-18',
};

const desktopSizes = {
  [ESpacerSize.s0]: 'desktop:h-0',
  [ESpacerSize.s4]: 'desktop:h-1',
  [ESpacerSize.s6]: 'desktop:h-1.5',
  [ESpacerSize.s8]: 'desktop:h-2',
  [ESpacerSize.s12]: 'desktop:h-3',
  [ESpacerSize.s16]: 'desktop:h-4',
  [ESpacerSize.s20]: 'desktop:h-4',
  [ESpacerSize.s24]: 'desktop:h-4',
  [ESpacerSize.s32]: 'desktop:h-8',
  [ESpacerSize.s40]: 'desktop:h-10',
  [ESpacerSize.s48]: 'desktop:h-12',
  [ESpacerSize.s56]: 'desktop:h-14',
  [ESpacerSize.s72]: 'desktop:h-18',
};

const gapRowStyles = cva('text-transparent', {
  variants: {
    mobile: sizes,
    desktop: desktopSizes,
  },
  defaultVariants: {
    desktop: ESpacerSize.s0,
    mobile: ESpacerSize.s0,
  },
});

export type TSpacerProps = {
  mobile?: ESpacerSize;
  desktop?: ESpacerSize;
};

export const Spacer: React.FC<TSpacerProps> = ({
  mobile = ESpacerSize.s0,
  desktop = ESpacerSize.s0,
}) => {
  const classes = gapRowStyles({ mobile, desktop });

  return <hr className={classes} />;
};
