import { ESpacerSize, Spacer, toEnum } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TSpacerConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'spacer' }
>;

const SpacerConnected: React.FC<TSpacerConnectedProps> = ({
  mobile,
  desktop,
}) => (
  <Spacer
    mobile={toEnum(ESpacerSize, mobile)}
    desktop={toEnum(ESpacerSize, desktop)}
  />
);
export default SpacerConnected;
