export enum ESpacerSize {
  s0 = 's_0',
  s4 = 's_4',
  s6 = 's_6',
  s8 = 's_8',
  s12 = 's_12',
  s16 = 's_16',
  s20 = 's_20',
  s24 = 's_24',
  s32 = 's_32',
  s40 = 's_40',
  s48 = 's_48',
  s56 = 's_56',
  s72 = 's_72',
}
